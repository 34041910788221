import Alpine from 'alpinejs'

import HamburgerMenu from './_hamburger_menu'
import CouriersTrackMap from './couriers/tracking'
import Performances from './couriers/performances'
import Managements from './couriers/managements'
import JoiningRequests from './couriers/joining_requests'
import Auth from './auth'

function RecruiterTools () {
  if (
    window.location.pathname.includes('recruiters_tools') ||
    window.location.hostname.includes('recruiters')
  ) {
    window.Alpine = Alpine
    Alpine.start()

    document.addEventListener('DOMContentLoaded', function () {
      RecruiterTools()
      const couriersTrackMap = new CouriersTrackMap()
      HamburgerMenu()
      Managements()

      const path = window.location.pathname
      switch (true) {
        case path.includes('login_page'):
          Auth()
          break

        case path.includes('couriers/performance_reports'):
          Performances()
          break

        case path.includes('couriers/tracking'):
          couriersTrackMap.init()
          break

        case path.includes('couriers/joining_requests'):
          JoiningRequests()
          break

        default:
          break
      }
    }, false)
  }
}

export default RecruiterTools
